import React from 'react'
import Helmet from 'react-helmet'

import PricingColumns from '../components/PricingColumns/PricingColumns'
import { metaData } from '../meta'

const PricingIndex = () => (
  <>
    <Helmet title={metaData.pricing.title}>
      <meta name="description" content={metaData.pricing.description} />
      <meta name="keywords" content={metaData.pricing.keywords} />
    </Helmet>
    <PricingColumns />
  </>
)

export default PricingIndex
