import React, { useState } from 'react'
import { Link } from 'gatsby'
import { navigate } from 'gatsby' // navigateTo is deprecated in gatsby v2, use navigate instead
// import { navigate } from 'gatsby';
import './PricingColumns.scss'

const PricingColumns = () => {
  const [pricing, setPricing] = useState('y')

  const goToGettingStartedPage = (event, query) => {
    if (event.target.href) {
      return
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'gtm-site-link-trial-marketing-how-to-get-started' })
    navigate(`/get-started${query || ''}`)
  }

  const monthClick = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'gtm-interactive-element-billing-monthly' })
    setPricing('m')
  }

  const annualClick = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: 'gtm-interactive-element-billing-annual' })
    setPricing('y')
  }

  return (
    <div style={{ maxWidth: '100%' }} className="wrapper pricing-columns">
      <div className="pricing-toggle-prefix">Select Billing</div>
      <div className="pricing-toggle">
        <div className={'pricing-toggle-option ' + (pricing === 'm' ? 'active' : '')} onClick={monthClick}>
          MONTHLY
        </div>
        <div className={'pricing-toggle-option ' + (pricing === 'y' ? 'active' : '')} onClick={annualClick}>
          ANNUAL
        </div>
        <div className="pricing-toggle-suffix">Save up to 25%!</div>
      </div>
      <div className="pricing-form">
        <div className="row">
          {/* <div className="col-md-4 pricing-col-padding">
            <a className="pricing-col" href="https://app.prod.ovationvr.com/signup" target="_blank" rel="noopener">
              <h4>Individual</h4>
              <h5>Monitor License</h5>
              <div className="pricing-col-price">
                <div className="pricing-col-price-row">
                  <div className="pricing-col-price-title">
                    <span className={pricing === 'y' ? 'previous-price' : ''}>$10</span>
                    {pricing === 'y' && ' $7.50'}
                  </div>
                  <div className="pricing-col-price-suffix">
                    per month
                    <br />
                    {pricing === 'y' && 'billed annually'}
                  </div>
                </div>
              </div>
              <div className="divider">
                <div className="divider-line" />
              </div>
              <div className="pricing-col-benefits">
                <div>
                  <b>Monitor Application</b>
                  <br />
                  <br />
                  <b>Web Portal Access</b>
                  <br />
                  <br />
                  <b>Email Support</b>
                </div>
              </div>
              <div className="divider">
                <div className="divider-line" />
              </div>
              <div className="pricing-col-cta-wrap">
                <div className="cta-prefix">Enjoy a 7-day free trial</div>
                <div className="pricing-col-cta">Get Started</div>
              </div>
            </a>
          </div> */}
          <div className="col-md-4 offset-md-2 pricing-col-padding">
            <a className="pricing-col" href="https://app.prod.ovationvr.com/signup" target="_blank" rel="noopener">
              <h4>Individual</h4>
              {/* <h5>VR License</h5> */}
              <div className="pricing-col-price">
                <div className="pricing-col-price-row">
                  <div className="pricing-col-price-title">
                    <span className={pricing === 'y' ? 'previous-price' : ''}>$20</span>
                    {pricing === 'y' && ' $15'}
                  </div>
                  <div className="pricing-col-price-suffix">
                    per month
                    <br />
                    {pricing === 'y' && 'billed annually'}
                  </div>
                </div>
              </div>
              <div className="divider">
                <div className="divider-line" />
              </div>
              <div className="pricing-col-benefits">
                <div>
                  <b>Monitor & VR Application</b>
                  <br />
                  <br />
                  <b>Web Portal Access</b>
                  <br /> <br />
                  <b>Email Support</b>
                </div>
              </div>
              <div className="divider">
                <div className="divider-line" />
              </div>
              <div className="pricing-col-cta-wrap">
                <div className="cta-prefix">Enjoy a 7-day free trial</div>
                <div className="pricing-col-cta">Get Started</div>
              </div>
            </a>
          </div>
          <div className="col-md-4 offset-md-0 pricing-col-padding">
            <div className="pricing-col" onClick={(e) => goToGettingStartedPage(e, '?subscription=organization')}>
              <h4>Organization</h4>
              {/* <h5>5 VR & 5 Monitor Licenses</h5> */}
              <h5>5 Licenses</h5>
              <div className="pricing-col-external-link">
                <a href="https://docs.ovationvr.com/ovation/creating-an-account/computer-licenses" target="__blank">
                  Learn more about our floating licenses
                </a>
              </div>
              <div className="pricing-col-price">
                <div className="pricing-col-price-row">
                  <div className="pricing-col-price-title">
                    <span className={pricing === 'y' ? 'previous-price' : ''}>$250</span>
                    {pricing === 'y' && ' $200'}
                  </div>
                  <div className="pricing-col-price-suffix">
                    per month
                    <br />
                    {pricing === 'y' && 'billed annually'}
                  </div>
                </div>
                <div className="pricing-col-edu">
                  *Includes 25%
                  <br />
                  Education & Non-Profit Discount
                </div>
              </div>
              <div className="pricing-col-price">
                <div className="pricing-col-price-row">
                  <div className="pricing-col-price-title">
                    <span className={pricing === 'y' ? 'previous-price' : ''}>$50</span>
                    {pricing === 'y' && ' $40'}
                  </div>
                  <div className="pricing-col-price-suffix">
                    per additional license <br />
                    per month{pricing === 'y' && ', billed annually'}
                  </div>
                </div>
              </div>
              <div className="divider">
                <div className="divider-line" />
              </div>
              <div className="pricing-col-benefits">
                <div>
                  <b>Monitor & VR Application</b>
                  <br />
                  <br />
                  <b>Unlimited Member Accounts</b>
                  <br />
                  <br />
                  <b>Review Member Usage</b>
                  <br />
                  <br />
                  <b>Software Training with Ovation Staff</b>
                  <br /> <br />
                  <b>Phone Support</b>
                </div>
              </div>
              <div className="divider">
                <div className="divider-line" />
              </div>
              <div className="pricing-col-cta-wrap">
                <div className="cta-prefix">Enjoy a 30-day free trial</div>
                <div className="pricing-col-cta">Get Started</div>
              </div>
            </div>
            {/* <a
              href="https://app.prod.ovationvr.com/signup"
              className="pricing-member-link gtm-site-link-org-member-join-organization"
            >
              Joining An Organization? Create Account Here
            </a> */}
          </div>
        </div>
        <div className="faq-link">
          For more information about subscriptions, licensing, and accounts, visit&nbsp;
          <a href="https://docs.ovationvr.com/ovation/faq/standalone-vr" target="__blank">
            our FAQ page
          </a>
          .
        </div>
      </div>

      {/* <div className="pricing-description">
        <div className="pricing-description-title">
          NEW TO VIRTUAL REALITY?
        </div>
        <div className="pricing-description-subtitle">
          To get you started with Ovation, you'll need:
        </div>
        <div className="row">
          <div className="col-sm-4">
            <div className="benefit-row">
              <div className="icon">
                <i className="far fa-check-square" />
              </div>
              <div className="text">
                <div className="title">VR-Ready PC</div>
                <div className="description">
                  Not all PC's are powerful enough for VR. Check our&nbsp;
                  <a
                    href="https://docs.ovationvr.com/ovation/hardware"
                    target="__blank"
                    className="pricing-description-sub gtm-outbound-link-documentation-hardware"
                  >minimum specifications</a>.
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="benefit-row">
              <div className="icon">
                <i className="far fa-check-square" />
              </div>
              <div className="text">
                <div className="title">VR Headset</div>
                <div className="description">
                  Ovation is available for most major VR platforms:
                </div>
                <div className="logos-row-wrap">
                  <img src="/OculusLogo.png" />
                  <img src="/ViveLogo.png" />
                  <img className="steam" src="/SteamVR.png" />
                  <img className="wmr" src="/WMRlogo.svg" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="benefit-row">
              <div className="icon">
                <i className="far fa-check-square" />
              </div>
              <div className="text">
                <div className="title">Ovation Software</div>
                <div className="description">
                  You can download Ovation from our web portal once your trial
                  is active.
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          onClick={() =>
            navigate('/contact')
          }
          className="pricing-description-sub gtm-site-link-assistance-help-purchasing-hardware"
        >
          NEED HELP PURCHASING HARDWARE? CONTACT US
        </a>
      </div> */}
    </div>
  )
}

export default PricingColumns
